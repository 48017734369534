.card-container {
  /*width: 200px;*/
  /*height: 300px;*/
  width: 100px;
  height: 150px;
  margin: 10px;
  perspective: 1000px; /* 添加3D效果 */
  transition: transform 1s ease-in-out; /* 确保添加了过渡效果 */

}

.card {
  width: 100px;
  height: 150px;
  /*width: 200px; !* 卡牌宽度 *!*/
  /*height: 300px; !* 卡牌高度 *!*/
  user-select: none;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out; /* 添加宽度和高度的过渡效果 */

}

.card-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-front, .card-back {

  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* 隐藏背面 */
}

.card-front {
  background-size: cover;
}

.card-back {
  background-size: cover;
  transform: rotateY(180deg); /* 翻转背面 */
}


/* 添加发光效果 */
.card-front, .card-back {
  box-shadow:0 0 15px rgba(255, 255, 255, 0.4);
}

.is-flipped {
  transform: rotateY(180deg);
}

.card-border {
  border-radius: 5px;
  box-shadow: inset 0 0 0 1.5px #fafbe8b3; /* 这里的颜色#ffffff可以根据需要更改 */
}
.card-desc {
  color: #edfff8;
}

.card-desc .card-name {
  position: absolute;
  top: 0;
  left: 5px;
  font-size: 12px;
  font-family: 'JHT', serif;
  font-weight: 300;
  margin-top: 5px;
  text-shadow: 0 0 2px #171717, 0 0 1px #171717; /* 简化的模糊边框效果 */
}
.card-desc .card-ability {
  font-size: 10px;
  margin-bottom:5px;
  font-family: 'JHT', serif;
  font-weight: 300;
  text-shadow: 0 0 2px #171717, 0 0 1px #171717; /* 简化的模糊边框效果 */
}

.fullscreen-overlay {
  position: fixed; /* 或 absolute，取决于布局 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.005); /* 半透明黑色蒙版 */
  z-index: 9; /* 确保它位于卡片下方但高于其他内容 */
}
